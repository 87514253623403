@charset "UTF-8";

@forward 'core';

/*********************************
COMMON
*********************************/
@import url(//fonts.googleapis.com/css2?family=Dancing+Script&family=M+PLUS+Rounded+1c:wght@300;400;500;700&display=swap);
@import url(//fonts.googleapis.com/icon?family=Material+Icons);
html{
    font-size: 10px;
    line-height: 1;
}

body {
    background: #ffffff;
    font-feature-settings: "palt";
	font-size: 14px;
	font-family: 'M PLUS Rounded 1c',"游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,sans-serif;
	// font-family: "游ゴシック Medium",YuGothic,YuGothicM,"Hiragino Kaku Gothic ProN","Hiragino Kaku Gothic Pro",メイリオ,Meiryo,sans-serif;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    line-height: 1.6;
    color: #212121;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
}

img{
    max-width: 100%;
    height: auto;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]), textarea {
    border: 2px solid #e0e0e0;
    font-size: 16px;
    padding: 4px;
    max-width: 100%;
}

input[type=text],
input[type=email],
input[type=number],
label,
select{
    height: 30px;
    line-height: 1;
}

input, textarea, button{
    max-width: 100%;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

}

select,
input[type=text],
input[type=email]{
    height: 40px;
}

input[type=text],
input[type=email],
input[type=submit],
textarea{
    -webkit-appearance: none;
}

input[type="text"]:focus,
textarea:focus{
    border: 2px solid #999999;
    outline: 0;
}

select{
    background-image: url(/images/common/arrow04.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 7px) 50%;
    background-color: #ffffff;
    font-size: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

::placeholder {
    color: #999999;
}
/* 旧Edge対応 */
::-ms-input-placeholder {
    color: #999999;
}
/* IE対応 */
:-ms-input-placeholder {
    color: #999999;
}
.browser-ie {
    select{
        background: #ffffff;
        padding-right: 10px;
    }
}

input[type=text],input[type=email],select{
    border: 2px solid #cccccc;
    border-radius: 5px;
}

input[type=radio]{
    background: #DEDEDE;
    border: 1px solid #C0C0C0;
    border-radius: 50%;
    margin: 0 5px 0 20px;
    position: relative;
    vertical-align: baseline;
}

input[type=radio]:checked:after {
    background: #000000;
    border-radius: 50%;
    bottom: 0;
    content: "";
    display: block;
    height: 12px;
    left: 1px;
    margin: auto;
    position: absolute;
    top: 0;
    width: 12px;
    -webkit-border-radius: 50%;
}

input[type=radio]:first-child{
    margin-left: 0;
}

textarea{
    resize: vertical;
}

a{
    color: #000000;
	-webkit-transition: opacity .2s linear 0s;
	-moz-transition: opacity .2s linear 0s;
	-o-transition: opacity .2s linear 0s;
	-ms-transition: opacity .2s linear 0s;
	transition: opacity .2s linear 0s;
    &:hover{
        opacity: .3;
    }
}


small {
    font-size: 80%;
}

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    vertical-align: top;
    font-size: 0;
    line-height: 0;
}

embed, object, iframe {
    max-width: 100%;
}

textarea{
    min-height: 50px;
    max-height: 200px;
    resize: vertical;
    width: 100%;
}

.alignnone {
    // margin: 5px 20px 20px 0;
}
 
.aligncenter,
div.aligncenter {
    display: block;
    // margin: 5px auto 5px auto;
    text-align: center;
}
 
.alignright {
    float:right;
    // margin: 5px 0 20px 20px;
}
 
.alignleft {
    float: left;
    // margin: 5px 20px 20px 0;
}
 
.aligncenter {
    display: block;
    margin: 0 auto;
    // margin: 5px auto 5px auto;
}
 
a img.alignright {
    float: right;
    // margin: 5px 0 20px 20px;
}
 
a img.alignnone {
    // margin: 5px 20px 20px 0;
}
 
a img.alignleft {
    float: left;
    // margin: 5px 20px 20px 0;
}
 
a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}
 
.wp-caption {
    background: #eee;
    border: 1px solid #ddd;
    max-width: 96%;
    padding: 10px;
}
 
.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}
 
.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}
 
.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}
 
.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.clearfix:after{
    clear: both;
    content: '';
    display: table;
    overflow: hidden;
}

.mobile-none{
    display: block !important;
}

.mobile-show{
    display: none !important;
}

.tac{
    text-align: center;
}

.mt10{
    margin-top: 10px;
}

.mt20{
    margin-top: 20px;
}