@charset "UTF-8";

@mixin text-hidden{
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin clearfix-selector{
	&:after{
		content: "";
		display: table;
		clear: both;
		font-size: 0;
		height: 0;
		visibility: hidden;
		line-height: 0;
	}
}

@mixin updownmixin(){
	span{
		display: inline-block;
	}
    &.on{
        span{
            animation: updown 0.7s linear 1s alternate;
        }
        span:nth-child(1){
            animation-delay: 1.0s;
        }
        span:nth-child(2){
            animation-delay: 1.1s;
        }
        span:nth-child(3){
            animation-delay: 1.2s;
        }
        span:nth-child(4){
            animation-delay: 1.3s;
        }
        span:nth-child(5){
            animation-delay: 1.4s;
        }
        span:nth-child(6){
            animation-delay: 1.5s;
        }
        span:nth-child(7){
            animation-delay: 1.6s;
        }
        span:nth-child(8){
            animation-delay: 1.7s;
        }
        span:nth-child(9){
            animation-delay: 1.8s;
        }
        span:nth-child(10){
            animation-delay: 1.9s;
        }
        span:nth-child(11){
            animation-delay: 2.0s;
        }
        span:nth-child(12){
            animation-delay: 2.1s;
        }
        span:nth-child(13){
            animation-delay: 2.2s;
        }
        span:nth-child(14){
            animation-delay: 2.3s;
        }
        span:nth-child(15){
            animation-delay: 2.4s;
        }
    }
}

.animation-delays{
	&:nth-child(1){
		animation-delay: 0 !important;
	}

	&:nth-child(2){
		animation-delay: 0.2s !important;
	}

	&:nth-child(3){
		animation-delay: 0.4s !important;
	}

	&:nth-child(4){
		animation-delay: 0.6s !important;
	}

	&:nth-child(5){
		animation-delay: 0.8s !important;
	}

	&:nth-child(6){
		animation-delay: 1s !important;
	}

	&:nth-child(7){
		animation-delay: 1.2s !important;
	}

	&:nth-child(8){
		animation-delay: 1.4s !important;
	}

	&:nth-child(9){
		animation-delay: 1.6s !important;
	}

	&:nth-child(10){
		animation-delay: 1.8s !important;
	}

	&:nth-child(11){
		animation-delay: 2s !important;
	}

	&:nth-child(12){
		animation-delay: 2.2s !important;
	}

	&:nth-child(13){
		animation-delay: 2.4s !important;
	}
}

.radiusClipPath{
	clip-path: polygon(2% 0%, 98% 0%, 100% 2%, 100% 98%, 98% 100%, 2% 100%, 0% 98%, 0% 2%);
}