@charset "UTF-8";

@use 'sass:math';
@use 'core';
@use 'reset';
@use 'common';
@use 'animation';

:root{
	--max-width: 1400px;
	--font-size01: 18px;
	--font-size02: 16px;
	--font-size03: 14px;
}

.box-type{
	&01{
		position: relative;
		&::after{
			background: #000000;
			bottom: -5px;
			content: '';
			height: 2px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			width: 50px;
		}
	}
	&02{
		border: 2px solid #000000;
	}
	&03{
		background: #000000;
		border-radius: 50px;
		color: #ffffff;
		text-align: center;
	}
	&04{
		background: #f18e24;
		border-radius: 10px;
	}
	&05{
		border: 2px solid #1783d1;
		border-radius: 10px;
	}
	&06{
		background: #1783d1;
	}
	&07{
		background: url(/images/common/arrow03.png) no-repeat calc(100% - 20px) 50% #deedf7;
		border: 2px solid #1783d1;
		border-radius: 10px;
		text-align: center;
		&:hover{
			background-position: calc(100% - 15px) 50%;
		}
	}
	&08{
		padding-left: 30px;
		position: relative;
		&::after{
			background: #000000;
			bottom: 0;
			content: '';
			display: block;
			height: 2px;
			left: 0;
			margin: auto;
			position: absolute;
			top: 0;
			width: 20px;
		}
	}
	&09{
		position: relative;
		&::after{
			background: #000000;
			bottom: 15px;
			content: '';
			height: 2px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			width: 100px;
		}
	}
	&10{
		a{
			background: #000000;
			color: #ffffff;
			padding: 10px 20px;
			text-decoration: none;
		}
	}
}

.list-type{
	&01{
		li{
			&:nth-child(odd){
				a{
					background-color: #f6feff;
				}
			}
			&:nth-child(even){
				a{
					background-color: #f6f6f6;
				}
			}
		}
		a{
			display: block;
			padding: 20px;
			text-decoration: none;
		}
	}
}

.table-type{
	&01{
		border-left: 2px solid #000000;
		border-top: 2px solid #000000;
		width: 100%;
		th,td{
			border-bottom: 2px solid #000000;
			border-right: 2px solid #000000;
		}
		th{
			background: #e7e7e7;
			color: #000000;
			text-align: left;
		}
	}
}

.input-text{
	&01{
		width: 30%;
	}

	&02{
		width: 100%;
	}

	&03{
		width: 25%;
	}

	&04{
		width: 40%;
	}

	&05{
		width: 70%;
	}
}

.select-type{
	&01{
		border: 2px solid #cccccc;
		border-radius: 5px;
		line-height: 1;
		padding: 10px;
		display: inline-block;
		width: 30%;
	}
	&02{
		padding: 0 5px;
		width: 100%;
	}
}

.label-type{
	&01{
		background: #ffffff;
		border: 2px solid hsl(0, 0%, 88%);
		border-radius: 20px;
		box-sizing: content-box;
		height: 1em;
		padding: 5px 10px;
		max-width: 100%;
		text-align: center;
		display: inline-block;
	}
}

.textarea-type{
	&01{
		border-radius: 5px;
		height: 300px;
		resize: vertical;
	}
}

.fc01{
	color: #ff0000;
}

.input-support{
	color: #2e1bad;
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
}

.send-btns{
	display: flex;
	flex-direction: row-reverse;
	margin-top: 50px;
	justify-content: center;
	input[type=submit],
	input[type=button],
	button{
		background: #000000;
		color: #ffffff;
		cursor: pointer;
		display: inline-block;
		font-size: 22px;
		margin-left: 20px;
		opacity: 1;
		padding: 10px 0;
		width: 40%;
		-webkit-transition: all .2s linear 0s;
		-moz-transition: all .2s linear 0s;
		-o-transition: all .2s linear 0s;
		-ms-transition: all .2s linear 0s;
		transition: all .2s linear 0s;
		&:hover{
			background: #ffffff;
			border: 2px solid #000000;
			color: #000000;
		}
		&:last-child{
			margin-left: 0;
		}

		&:disabled{
			background-color: #cccccc;
			cursor: default;
			pointer-events: none;
		}
	}
}

.pager-area{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.pager{
	margin-left: 10px;
	&:first-child {
		margin-left: 0;
	}
	
	a {
		background: #000000;
		box-sizing: border-box;
		color: #ffffff;
		display: inline-block;
		height: 30px;
		line-height: 1.4;
		text-align: center;
		text-decoration: none;
		padding: 5px;
		width: 30px;
	}
}

.pager-ctrl{
	position: relative;
	a {
		display: block;
		font-size: 0;
		position: relative;
		&::after{
			height: 22px;
			bottom: 0;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.pager-ctrl--prev{
	a{
		&::after{
			content: url(/images/common/arrow02.png);
			transform: rotateY(180deg);
		}
	}
}

.pager-ctrl--next{
	a {
		&::after {
			content: url(/images/common/arrow02.png);
		}
	}
}

.pager-number{
	a:hover,
	&.current {
		background: #ffffff;
		border: 2px solid #000000;
		color: #000000;
		height: 30px;
		line-height: 1.4;
		text-align: center;
		text-decoration: none;
		padding: 5px;
		width: 30px;
	}
}

.logo-style{
	font-family: 'Dancing Script', cursive;
	font-size: 35px;
	a{
		line-height: 1;
	}
	small{
		display: block;
		font-size: .5em;
		line-height: 1;
		margin-top: -10px;
		text-align: center;
	}
}

.site-contents{
	background: url(/images/common/bg02.png) no-repeat 10px 10px, url(/images/common/bg01.png) no-repeat calc(100% - 10px) 10px,
		url(/images/common/bg03.png) no-repeat calc(100% - 10px) calc(100% - 50px),
			url(/images/common/bg04.png) no-repeat 10px calc(100% - 50px);
	overflow: hidden;
	position: relative;
}

.global-header{
	.inner{
		min-height: 130px;
		max-width: var(--max-width);
		margin: 0 auto;
		padding: 20px 0;
		position: relative;
		text-align: center;
	}
	h1{
		text-align: left;
	}
	.logo{
		background: url(/images/common/logo-parts01.png) no-repeat 50% 0, url(/images/common/logo-parts01.png) no-repeat 50% 100%;
		padding: 30px 0 50px;
		position: relative;
		@extend .logo-style;
		a{
			text-decoration: none;
		}
		.studio{
			font-size: 12px;
			position: absolute;
		}
		#studio01{
			bottom: 20px;
			left: 30px;
		}
		#studio02{
			bottom: 20px;
			right: 30px;
		}
	}
	.members,
	.tel{
		a{
			display: block;
			min-width: 250px;
			span{
				min-height: 25px;
			}
		}
	}
	.members{
		position: absolute;
		right: 0;
		top: 70px;
		a{
			span{
				background: url("/images/common/icon-key02.png") no-repeat 0 50%;
				display: inline-block;
				padding-left: 35px;
			}
		}
	}
	.tel{
		position: absolute;
		right: 0;
		top: 10px;
		a{
			span{
				background: url("/images/common/icon-tel01.png") no-repeat 0 50%;
				display: inline-block;
				padding-left: 45px;
			}
		}
	}
}

.header__left{
	width: 100%;
}

.header__right{
	text-align: right;
	width: 100%;
	a{
		display: block;
		text-decoration: none;
	}
}

.global-navi{
	position: relative;
	z-index: 100;
	ul{
		display: flex;
		flex-wrap: wrap;
		max-width: var(--max-width);
		margin: 0 auto;
		position: relative;
		justify-content: center;
	}
	li{
		text-align: center;
		// width: math.div(100, 6) * 1%;
		width: percentage($number: math.div(1, 6));
		position: relative;
		&::after{
			background: #000000;
			bottom: 0;
			content: '';
			height: 50%;
			margin: auto;
			position: absolute;
			right: -1px;
			top: 0;
			width: 2px;
		}
		&:first-child{
			&::before{
				background: #000000;
				bottom: 0;
				content: '';
				height: 50%;
				margin: auto;
				position: absolute;
				left: -1px;
				top: 0;
				width: 2px;
			}
		}
	}
	a{
		align-items: center;
		background-repeat: no-repeat;
		background-position: 50% 5px;
		color: #000000;
		display: flex;
		font-size: var(--font-size03);
		height: 100%;
		opacity: 1;
		padding-top: 65px;
		padding-bottom: 20px;
		position: relative;
		text-decoration: none;
		justify-content: center;
		-webkit-transition: background-position .2s linear 0s;
		-moz-transition: background-position .2s linear 0s;
		-o-transition: background-position .2s linear 0s;
		-ms-transition: background-position .2s linear 0s;
		transition: background-position .2s linear 0s;
		&::after{
			background: #000000;
			content: '';
			height: 1px;
			width: 0;
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transition: width .1s linear 0s;
			-moz-transition: width .1s linear 0s;
			-o-transition: width .1s linear 0s;
			-ms-transition: width .1s linear 0s;
			transition: width .1s linear 0s;
		}
		&:hover{
			background-position: 50% 0 !important;
			&::after{
				width: 60%;
			}
		}
	}
	.item01 a{
		background-image: url(/images/common/navi-item01.png);
	}
	.item02 a{
		background-image: url(/images/common/navi-item02.png);
	}
	.item03 a{
		background-image: url(/images/common/navi-item03.png);
	}
	.item04 a{
		background-image: url(/images/common/navi-item04.png);
	}
	.item05 a{
		background-image: url(/images/common/navi-item05.png);
	}
	.item06 a{
		background-image: url(/images/common/navi-item06.png);
	}
}

.site-section{
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
}

.image-line{
	height: 300px;
	overflow: hidden;
	width: 100%;
	img{
		height: auto;
		width: 100%;
	}
}

.sns-btns{
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	margin-top: 100px;
	justify-content: center;
	li{
		text-align: center;
		width: percentage($number: math.div(1, 5));
        &.on{
            animation: upAnime .5s linear 0s 1 forwards;
        }
		@extend .animation-delays;
	}
}

.navi-overlay{
	background: rgba(0,0,0,.5);
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 115;
}

.navi-menu-btn{
	color: #0288e2;
	cursor: pointer;
	display: none;
	font-size: 12px;
	height: 40px;
	position: fixed;
	right: 10px;
	text-align: center;
	top: 10px;
	padding-top: 35px;
	width: 40px;
	z-index: 11000;
	span{
		background: #0288e2;
		border-radius: 5px;
		display: inline-block;
		height: 5px;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		width: 80%;
		-webkit-transition: all .2s linear 0s;
		-moz-transition: all .2s linear 0s;
		-o-transition: all .2s linear 0s;
		-ms-transition: all .2s linear 0s;
		transition: all .2s linear 0s;	
		&:nth-child(1){
			top: 5px;
		}
		&:nth-child(2){
			bottom: 0;
			margin: auto;
			top: 0;
			-webkit-transition: all .5s linear 0s;
			-moz-transition: all .5s linear 0s;
			-o-transition: all .5s linear 0s;
			-ms-transition: all .5s linear 0s;
			transition: all .5s linear 0s;	
		}
		&:nth-child(3){
			bottom: 5px;
		}
	}
	&.open{
		span{
			&:nth-child(1){
				position: absolute;
				top: 15px;
				transform: rotate(45deg);
			}
			&:nth-child(2){
				// display: none;
				transform: translateX(50px);
			}
			&:nth-child(3){
				bottom: 20px;
				position: absolute;
				transform: rotate(-45deg);
			}
		}
	}
}

.breadcrumb{
	border-bottom: 2px solid #000000;
	border-top: 2px solid #000000;
	ul{
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		max-width: 1400px;
		position: relative;
		z-index: 20;

	}
	li{
		position: relative;
		a{
			background: url(/images/common/arrow01.png) no-repeat calc(100% - 10px) 50%;
			display: inline-block;
			padding: 10px 30px 10px 0;
			white-space: nowrap;
		}
		
		&:last-child{
			a{
				background: none;
				padding-right: 0;
				cursor: default;
				opacity: 1;
				filter: alpha(opacity=(1 * 100));
				text-decoration: none;
				pointer-events: none;
			}
		}
	}
}

.page-title,
.page-subtitle{
	font-size: var(--font-size01);
	text-align: center;
	padding: 20px 0;
}

.page-contents,
.page-subcontents{
	margin: 20px auto 0;
    position: relative;
}

.error-contents_contents{
	margin: 0 auto;
	max-width: var(--max-width);
}
#ClipPaths{
	height: 0;
	overflow: hidden;
	position: absolute;
	width: 0;
}

.btn-pagetop{
	bottom: 100px;
	position: fixed;
	right: 50px;
	-webkit-transition: -webkit-transform .3s linear 0s;
	-moz-transition: -moz-transform .3s linear 0s;
	transition: transform .3s linear 0s;
	z-index: 100;
	-webkit-transform: translateX(100px);
	-moz-transform: translateX(100px);
	transform: translateX(100px);
	a{
		background: url(/images/common/btn-top.png) no-repeat 0 0;
		display: block;
		height: 40px;
		opacity: 1;
		width: 40px;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		-webkit-transition: -webkit-transform, background-position .3s, 2s linear 0s;
		-moz-transition: -moz-transform, background-position .3s, 2s linear 0s;
		transition: transform, background-position .3s, .2s linear 0s;
		&:hover{
			background-position: 0 -40px !important;
		}
	}
	&.fix{
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		transform: translateX(0);
	}
}

.global-footer {
	border-top: 10px solid #000000;
	margin-top: 50px;
	.inner{
		max-width: var(--max-width);
		margin: 0 auto;
		padding: 10px 0;
	}
	h3{
		a{
			text-decoration: none;
		}
	}
	.copyright{
		background: #000000;
		color: #ffffff;
		margin-top: 30px;
		padding: 5px 0;
		text-align: center;
	}
}

.footer-util{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	li{
		margin-bottom: 10px;
		margin-left: 5%;
		text-align: center;
		width: 16%;
		&:nth-child(4n+1){
			margin-left: 0;
		}
	}
	a{
		display: block;
		font-size: var(--font-size03);
		opacity: 1;
		position: relative;
		text-decoration: none;
		&::after {
			background: #000000;
			content: '';
			height: 1px;
			width: 0;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transition: width .1s linear 0s;
			-moz-transition: width .1s linear 0s;
			-o-transition: width .1s linear 0s;
			-ms-transition: width .1s linear 0s;
			transition: width .1s linear 0s;
		}
	
		&:hover {
			background-position: 50% 0 !important;
	
			&::after {
				width: 60%;
			}
		}
	}
}

.footer-studio{
	margin-top: 20px;
	text-align: center;
	h3{
		@extend .logo-style;
		margin-bottom: 10px;
	}
}

.footer-studio__info{
	margin: 0 auto;
	text-align: left;
	width: 80%;
	tr:first-child{
		th,td{
			padding-top: 0;
		}
	}
	th,td{
		padding-top: 20px;
	}
	th{
		padding-right: 20px;
	}
}


.bd-about .global-navi .item01 a,
.bd-course .global-navi .item02 a,
.bd-teacher .global-navi .item03 a,
.bd-record .global-navi .item04 a{
	background-position: 50% 0 !important;
	&::after{
		width: 60%;
	}
}

.no-data{
	width: 100% !important;
}

.require{
	background: #000000;
	color: #ffffff;
	display: inline-block;
	line-height: 1;
	padding: 10px;
}

.error-contents_title{
	font-size: var(--font-size01);
	font-weight: bold;
	text-align: center;
}

.js-fadein{
	opacity: 0;
	&.on {
		animation: upAnime .5s linear 0s 1 forwards;
	}
}

.mwform-checkbox-field{
	label{
		@extend .label-type01;
	}
}
/****************************************
メディアクエリ
****************************************/
@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1200px) {
	.global-header .inner,
	.global-navi,
	.breadcrumb,
	.site-section,
	.privacy-area,
	.error-contents_contents,
	.global-footer .inner{
		padding-left: 20px;
		padding-right: 20px;
	}
	
	.global-header{
		.members,
		.tel{
			right: 20px;
		}
	}

	.global-navi{
		a{
			font-size: var(--font-size03);
		}
	}
}	

@media screen and (min-width: 1000px) {
	.global-navi {
		display: block !important;
	}
	
}

@media screen and (max-width: 1000px) {
	.global-header{
		.inner{
			display: block;
		}
	}
	.global-navi {
		background: none;
		border-bottom: none;
		box-shadow: none;
		display: none;
		height: 100%;
		left: 0;
		padding: 10px;
		position: fixed;
		top: 0;
		width: 100%;
		ul {
			background: #ffffff;
			display: block;
			height: 100%;
			overflow: scroll;
			padding: 20px;
			z-index: 120;
			-webkit-overflow-scrolling: touch;
	}

		li {
			border-bottom: 2px solid #000000;
			width: 100%;
			&::before, &::after{
				content: none !important;
			}
		}
	}

	.navi-overlay {
		display: block;
	}

	.navi-menu-btn {
		display: block;
	}

	.contact-box{
		a{
			width: 100%;
		}
	}
	.contact-box__guide{
		text-align: center;
		width: 100%;
	}
	.footer-studio__info{
		width: 100%;
	}
}

@media screen and (max-width: 900px) {
	.global-header{
		.members,
		.tel{
			position: static;
			margin-top: 10px;
		}
	}
	.page-title,
	.page-subtitle{
		font-size: var(--font-size01);
	}

	.send-btns{
		flex-wrap: wrap;
		flex-direction: unset;
		input[type=submit],
		input[type=button],
		button{
			margin: 20px 0 0;
			width: 100%;
			&:first-child{
				margin-top: 0;
			}
			&:last-child{
				margin-left: 0;
			}
		}
	}

    .image-line{
        height: auto !important;
        margin-top: 30px !important;
		img{
			transform: translate(0, 0) !important;
		}
    }
	.sns-btns{
		margin-top: 50px;
		justify-content: space-between;
		li{
			margin-top: 20px;
			width: 48%;
			&:nth-child(-n + 2){
				margin-top: 0;
			}
		}
	}
	.btn-pagetop{
		right: 0;
	}
	.footer-util{
		li{
			margin: 0;
			padding: 0 10px;
		}
	}
}

@media screen and (max-width: 700px) {

}

@media screen and (max-width: 600px) {
	.site-contents{
		background-size: 25%;
		background-position: 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 80px),10px calc(100% - 80px);
	}
	.global-header{
		h1{
			text-align: center;
			width: 100%;
		}
		.logo{
			background-size: 60%;
		}
	}

	.global-header .inner,
	.breadcrumb,
	.global-footer .inner,
	.page-contents
	{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.site-section{
		padding-left: 0;
		padding-right: 0;
	}

	.visual_title{
		font-size: var(--font-size01);
		margin-bottom: 20px;
	}

	.footer-util{
		li{
			margin-top: 10px;
			padding: 0;
			width: 100%;
			&:first-child{
				margin-top: 0;
			}
		}
	}

	.footer-studio__info{
		th,td{
			display: block;
			width: 100%;
		}
		th{
			font-weight: bold;
			padding-right: 0;
		}
		td{
			padding-top: 0;
		}
	}

	.logo-style{
		font-size: 35px;
	}
}

@media screen and (max-width: 400px) {

}